(function($){
    $(document).ready(function(){
        $('.imgGridItems, .stepsItems').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            centerMode: true,
            centerPadding: '0',
            prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none"><rect width="50" height="50" fill="#1E77BA"/><path d="M28 33L20 25L28 17" stroke="white" stroke-width="2"/></svg></button>',
            nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none"><rect width="50" height="50" transform="matrix(-1 0 0 1 50 0)" fill="#1E77BA"/><path d="M22 33L30 25L22 17" stroke="white" stroke-width="2"/></svg></button>',
            responsive: [
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                        dots: true
                    }
                }
              ]

        });
        $('.iconsCarouselItems').slick({
            slidesToShow: 5,
            infinite: true,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 11 18" fill="none"><path d="M10 17L2 9L10 1" stroke="#2979BE" stroke-width="2"/></svg></button>',
            nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 11 18" fill="none"><path d="M1 17L9 9L1 1" stroke="#2979BE" stroke-width="2"/></svg></button>',
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 980,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
              ]

        });
    });

    function googleReviewsSlider() {
        $('.sk_reviews_grid:not(.slick-slider)').slick({
            slidesToShow: 3,
            infinite: true,
            slidesToScroll: 1,
            arrows: true,
            slide: '.sk_reviews_grid-item',
            prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none"><rect width="50" height="50" fill="#1E77BA"/><path d="M28 33L20 25L28 17" stroke="white" stroke-width="2"/></svg></button>',
            nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none"><rect width="50" height="50" transform="matrix(-1 0 0 1 50 0)" fill="#1E77BA"/><path d="M22 33L30 25L22 17" stroke="white" stroke-width="2"/></svg></button>',
            responsive: [
                {
                    breakpoint: 980,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        arrows: false,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                        dots: true
                    }
                }
            ]
        });



        $(window).on('resize', function(){
            $('.sk_reviews_grid.slick-slider').slick('setPosition');
        })
    }

    $(window).on('load', function(){
        const reviews = $('.sk-ww-google-reviews');
        if (reviews.length) {
            reviews.css('max-height', 'none');
        }
    })

    // Image comparison
    $(document).ready(function(){
        $('.beforeAfterImages').slick({
            slidesToShow: 1,
            infinite: true,
            slidesToScroll: 1,
            arrows: true,
            draggable: false,
            swipeToSlide: false,
            swipe: false,
            touchMove: false,
            prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none"><rect width="50" height="50" fill="#1E77BA"/><path d="M28 33L20 25L28 17" stroke="white" stroke-width="2"/></svg></button>',
            nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none"><rect width="50" height="50" transform="matrix(-1 0 0 1 50 0)" fill="#1E77BA"/><path d="M22 33L30 25L22 17" stroke="white" stroke-width="2"/></svg></button>',
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        dots: true
                    }
                }
            ]
        });

        $('.images-compare-container').each(function(){
            var cur = $(this);
            // Adjust the slider
            var width = cur.width()+'px';
            cur.find('.resize img').css('width', width);
            // Bind dragging events
            drags(cur.find('.handle'), cur.find('.resize'), cur);
        });
    });

    // Update sliders on resize.
    $(window).resize(function(){
        $('.images-compare-container').each(function(){
            let cur = $(this);
            let width = cur.width()+'px';
            cur.find('.resize img').css('width', width);
        });
    });

    $('.beforeAfterImages').on('setPosition', function(){
        $('.images-compare-container').each(function(){
            let cur = $(this);
            let width = cur.width()+'px';
            cur.find('.resize img').css('width', width);
        });
    });
    function drags(dragElement, resizeElement, container) {

        // Initialize the dragging event on mousedown.
        dragElement.on('mousedown touchstart', function(e) {

            dragElement.addClass('img-draggable');
            resizeElement.addClass('img-resizable');

            // Check if it's a mouse or touch event and pass along the correct value
            let startX = (e.pageX) ? e.pageX : e.originalEvent.touches[0].pageX;

            // Get the initial position
            let dragWidth = dragElement.outerWidth(),
                posX = dragElement.offset().left + dragWidth - startX,
                containerOffset = container.offset().left,
                containerWidth = container.outerWidth();

            // Set limits
            let offset = screen.width < 690 ? 15 : 30,
                minLeft = containerOffset + offset,
                maxLeft = containerOffset + containerWidth - dragWidth - offset;

            // Calculate the dragging distance on mousemove.
            dragElement.parents().on("mousemove touchmove", function(e) {

                // Check if it's a mouse or touch event and pass along the correct value
                let moveX = (e.pageX) ? e.pageX : e.originalEvent.touches[0].pageX;

                leftValue = moveX + posX - dragWidth;

                // Prevent going off limits
                if ( leftValue < minLeft) {
                    leftValue = minLeft;
                } else if (leftValue > maxLeft) {
                    leftValue = maxLeft;
                }

                // Translate the handle's left value to masked divs width.
                widthValue = (leftValue + dragWidth/2 - containerOffset)*100/containerWidth+'%';

                // Set the new values for the slider and the handle.
                // Bind mouseup events to stop dragging.
                $('.img-draggable').css('left', widthValue).on('mouseup touchend touchcancel', function () {
                    $(this).removeClass('img-draggable');
                    resizeElement.removeClass('img-resizable');
                });
                $('.img-resizable').css('width', widthValue);
            }).on('mouseup touchend touchcancel', function(){
                dragElement.removeClass('img-draggable');
                resizeElement.removeClass('img-resizable');
            });
            e.preventDefault();
        }).on('mouseup touchend touchcancel', function(e){
            dragElement.removeClass('img-draggable');
            resizeElement.removeClass('img-resizable');
        });
    }
    // end of image comparison

    //Copy current url
    $('#copyBtn').click(function(){
        // Create a temporary input element and copy the URL
        $('<input>').val(window.location.href).appendTo('body').select();
        document.execCommand('copy').remove();

        // Optional: Display a confirmation message
        alert('Page URL copied to clipboard!');
    });

    // Create a MutationObserver to monitor changes in the DOM ro remove google reviews branding temporarily
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
            mutation.addedNodes.forEach((node) => {
                if (node.nodeType === Node.ELEMENT_NODE) {
                if (document.querySelector('.sk_reviews_grid')) {
                    setTimeout(() => {
                        googleReviewsSlider();
                        observer.disconnect();
                    }, 500);
                    }
                }
            });
            });
        });

        // Configuration of the observer
        const config = {
            childList: true,
            subtree: true
        };

        observer.observe(document.body, config);
})(jQuery);
